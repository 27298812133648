import React from "react";
import styled from "styled-components";
import { Button } from "../../ui/Button";
import { Player } from "./Player";

const MatchWrapper = styled.div`
  margin: 0;
  padding: 5px 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  > h5 {
    margin: 5px 0 15px 0;
    font-weight: var(--font-weight-light);
  }
  .player-result {
    display: flex;
    width: 100%;
  }
  .players {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1rem;
    flex-grow: 1;
  }
  .result-wrapper {
    padding: 0;
    margin-left: 0.5rem;
    cursor: pointer;
    align-items: center;
    &.numbers {
      width: 15px;
    }
    .result {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      &.completed {
        font-weight: var(--font-weight-bold);
      }
      &.incomplete {
        color: #aaa;
      }
    }
    .report {
      margin: 10px 0;
      padding: 0 6px;
      height: 30px;
      font-size: 0.7rem;
    }
  }
`;

export const Match = ({
  match,
  reportIndex,
  toggleReportResult,
  reportResults,
  updateResult,
  set,
}) => {
  const matchCompleted =
    (match.result.player1 === set || match.result.player2 === set) &&
    (match.result.player1 > 0 || match.result.player2 > 0);

  const reportResult = reportResults[match.matchId];

  const togglebutton =
    !match.result?.player1 &&
    !match.result?.player2 &&
    match.player1.name &&
    match.player2.name &&
    !reportResult;

  return (
    <MatchWrapper key={match.matchId} className="match">
      <span className="bracket" />
      <span className="connector" />
      <h5>Match {match.matchId}</h5>
      <div className="player-result">
        <div className="players">
          <Player
            player="player1"
            opponent="player2"
            match={match}
            reportIndex={reportIndex}
            reportResults={reportResults}
            updateResult={updateResult}
            set={set}
          />
          <Player
            player="player2"
            opponent="player1"
            match={match}
            reportIndex={reportIndex}
            reportResults={reportResults}
            updateResult={updateResult}
            set={set}
          />
        </div>
        <div
          className={`result-wrapper ${togglebutton ? "button" : "numbers"}`}
          onClick={() => matchCompleted && toggleReportResult(match.matchId)}
        >
          <div
            className={`result ${matchCompleted ? "completed" : "incomplete"}`}
          >
            <span>{match.result?.player1}</span>
            {togglebutton && (
              <Button
                className="report small"
                onClick={() => toggleReportResult(match.matchId)}
              >
                Rapportera
              </Button>
            )}
            <span>{match.result?.player2}</span>
          </div>
        </div>
      </div>
    </MatchWrapper>
  );
};
