import { useOutletContext } from "react-router";
import GroupKnockoutResult from "./GroupKnockout/GroupKnockoutResult";
import KnockoutResult from "./Knockout/KnockoutResult";
import ResultsTable from "./RoundRobin/ResultsTable";

const Result = () => {
  const { schedule } = useOutletContext();
  if (schedule.type === "group-knockout") return <GroupKnockoutResult />;
  if (schedule.type === "knockout") return <KnockoutResult />;
  if (schedule.type === "round-robin") return <ResultsTable />;
  return null;
};

export default Result;
