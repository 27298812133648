import React from "react";
import generateGroupSchedule, {
  groupPlayersByRanking,
  groupPlayersWithSeedings,
} from "../Common/generateGroupSchedule";
import { Button } from "../../ui/Button";
import { useOutletContext } from "react-router";
import GroupSchedule from "./GroupSchedule";
import { ToggleTitle } from "../../ui/ToggleTitle";
import { PlayerMessage } from "../Players";
import { ContestPageTitle } from "../Contest";
import { ResultsTable } from "./ResultsTable";
import { MaxWidthWrapper } from "../../ui/MaxWidth";

const RoundRobin = ({ smallGroup = false }) => {
  const {
    schedule,
    setSchedule,
    minPlayers,
    hasPlayers,
    updateResult,
    reportResults,
    toggleReportResult,
    saveTournament,
  } = useOutletContext();

  // Function to generate the schedule
  const generateScheduleHandler = () => {
    if (hasPlayers) {
      const groupOfPlayers = smallGroup
        ? groupPlayersWithSeedings(schedule.players)
        : groupPlayersByRanking(schedule.players);
      const newSchedule = { ...schedule };
      newSchedule.groupOfPlayers = groupOfPlayers;
      newSchedule.groups = groupOfPlayers.map((players) => {
        return generateGroupSchedule(players);
      });
      newSchedule.rounds = [];
      newSchedule.type = smallGroup ? "group-knockout" : "round-robin";
      newSchedule.id = Date.now();
      setSchedule(newSchedule);
      saveTournament(newSchedule);
    } else {
      alert(`Lägg till minst ${minPlayers} spelare`);
    }
  };

  const hasSchedule = schedule.groups?.length > 0;

  return (
    <MaxWidthWrapper>
      {hasPlayers && !hasSchedule ? (
        <Button
          className="generate-schedule"
          onClick={() => generateScheduleHandler()}
        >
          Skapa spelschema
        </Button>
      ) : (
        <ContestPageTitle>Spelschema</ContestPageTitle>
      )}
      {!hasPlayers && <PlayerMessage />}
      {hasSchedule &&
        schedule.groups.map((group, groupIndex) => (
          <ToggleTitle
            key={groupIndex}
            title={`${smallGroup ? "Pool" : "Division"} ${groupIndex + 1}`}
          >
            {smallGroup && (
              <ResultsTable className="no-toplist" groupIndex={groupIndex} />
            )}
            <GroupSchedule
              group={group}
              groupIndex={groupIndex}
              updateResult={updateResult}
              reportResults={reportResults}
              toggleReportResult={toggleReportResult}
              set={schedule.set}
            />
          </ToggleTitle>
        ))}
    </MaxWidthWrapper>
  );
};

export default RoundRobin;
