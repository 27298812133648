import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons";

const PenIcon = ({ className = "", ...rest }) => {
  return (
    <FontAwesomeIcon icon={faPenToSquare} {...rest} className={className} />
  );
};

export default PenIcon;
