import React from "react";
import { Button } from "../ui/Button";
import styled from "styled-components";
import { useOutletContext } from "react-router";
import PlayerForm from "./Players/PlayerForm";
import PlayerListView from "./Players/PlayerListView";
import { ContestPageSubTitle, ContestPageTitle } from "./Contest";
import { MaxWidthWrapper } from "../ui/MaxWidth";

const PlayersWrapper = styled.div``;

const PlayerMessageWrapper = styled.div`
  text-align: center;
`;

export const PlayerMessage = () => {
  const { schedule, minPlayers } = useOutletContext();
  return (
    <PlayerMessageWrapper className="warning">
      <p>
        Lägg till {minPlayers - schedule.players.length} spelare för att kunna
        generera ett spelschema
      </p>
    </PlayerMessageWrapper>
  );
};

const Players = () => {
  const {
    addPlayer,
    removePlayer,
    toggleEditForm,
    editingPlayer,
    updatePlayer,
    clearPlayers,
    hasPlayers,
    schedule,
    scheduleOngoing,
  } = useOutletContext();

  const { players } = schedule;

  return (
    <MaxWidthWrapper>
      <PlayersWrapper>
        <ContestPageTitle>Spelare ({players.length})</ContestPageTitle>
        {scheduleOngoing && (
          <p className="schedule-warning">
            Obs! Denna klass är nu igång. Om du förändrar spelarlistan nu kommer
            spelschemat att behöva skapas på nytt.
          </p>
        )}
        <ContestPageSubTitle>Lägg till Spelare</ContestPageSubTitle>
        <PlayerForm addPlayer={addPlayer} />
        <ContestPageSubTitle>Spelarlista</ContestPageSubTitle>
        <PlayerListView
          players={players}
          removePlayer={removePlayer}
          toggleEditForm={toggleEditForm}
          editingPlayer={editingPlayer}
          updatePlayer={updatePlayer}
        >
          {players.length > 0 && (
            <Button className="text" onClick={clearPlayers}>
              Rensa spelare
            </Button>
          )}
        </PlayerListView>
        {!hasPlayers && <PlayerMessage />}
      </PlayersWrapper>
    </MaxWidthWrapper>
  );
};

export default Players;
