import { useOutletContext } from "react-router";
import { Link } from "react-router-dom";
import GroupKnockout from "./GroupKnockout/GroupKnockout";
import Knockout from "./Knockout/Knockout";
import RoundRobin from "./RoundRobin/RoundRobin";

const Schedule = () => {
  const { schedule } = useOutletContext();
  if (schedule.type === "knockout") return <Knockout />;
  if (schedule.type === "round-robin") return <RoundRobin />;
  if (schedule.type === "group-knockout") return <GroupKnockout />;
  return (
    <p>
      Välj spelform under <Link to="/tavling/installning">inställningar</Link>
    </p>
  );
};

export default Schedule;
