import React from "react";
import { useOutletContext } from "react-router";
import { generateKnockoutTreeFromGroups } from "../Common/generateKnockoutTree";
import styled from "styled-components";
import { ContestMessage, ContestPageTitle } from "../Contest";
import KnockoutTree from "../Knockout/KnockoutTree";
import { Button } from "../../ui/Button";
import { NoWidthWrapper } from "../../ui/MaxWidth";

const GroupKnockoutTreeWrapper = styled.div`
  .knockout-title {
    margin-top: 2rem;
  }
`;

const GroupKnockoutTree = () => {
  const {
    schedule,
    setSchedule,
    saveTournament,
    updateResult,
    reportResults,
    toggleReportResult,
  } = useOutletContext();

  const generateScheduleHandler = () => {
    const newSchedule = { ...schedule };
    newSchedule.rounds = generateKnockoutTreeFromGroups(
      schedule.groupOfPlayers,
      schedule.groups
    );
    setSchedule(newSchedule);
    saveTournament(newSchedule);
  };

  const GroupStageComplete = true;
  const hasRounds = schedule.rounds.length > 0;

  return (
    <NoWidthWrapper>
      <GroupKnockoutTreeWrapper>
        {GroupStageComplete ? (
          <Button
            className="generate-schedule"
            onClick={generateScheduleHandler}
          >
            Skapa slutspelet
          </Button>
        ) : (
          <ContestMessage>Poolspelet är ej färdigspelat</ContestMessage>
        )}
        {hasRounds && <ContestPageTitle>Slutspel</ContestPageTitle>}
        <KnockoutTree
          {...{ schedule, updateResult, reportResults, toggleReportResult }}
        />
      </GroupKnockoutTreeWrapper>
    </NoWidthWrapper>
  );
};

export default GroupKnockoutTree;
