import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

const DownIcon = ({ className = "", ...rest }) => {
  return (
    <FontAwesomeIcon icon={faChevronDown} {...rest} className={className} />
  );
};

export default DownIcon;
