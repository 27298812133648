export function generatePlayerId(name, ranking) {
  let hash = 0;
  const str = name + ranking;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }

  return hash.toString();
}
