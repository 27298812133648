import React from "react";
import styled from "styled-components";
import DeleteIcon from "../../ui/FontIcons/DeleteIcon";
import PenIcon from "../../ui/FontIcons/PenIcon";
import PlayerEditForm from "./PlayerEditForm";

const PlayerListWrapper = styled.div`
  margin-bottom: 2rem;
  .children-wrapper {
    text-align: right;
    > button {
      display: inline-block;
    }
  }
`;

const PlayerList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
    padding: 1rem 0;
    div {
      display: flex;
      align-items: center;
      width: 100%;
      > span {
        flex-grow: 1;
      }
      button {
        background: none;
        border: none;
        cursor: pointer;
        &:last-child {
          margin-left: 5px;
        }
      }
      svg {
        font-size: 1.2rem;
      }
    }
    form {
      margin-top: 1rem;
    }
  }
`;

const PlayerListView = ({
  players,
  removePlayer,
  toggleEditForm,
  editingPlayer,
  updatePlayer,
  children,
}) => {
  return (
    <PlayerListWrapper>
      <PlayerList>
        {players.map((player) => (
          <li key={player.id}>
            <div>
              <span>
                {player.name} ({player.ranking})
              </span>
              <button
                className="secondary small"
                onClick={() => toggleEditForm(player.id)}
              >
                {editingPlayer === player.id ? "Stäng" : <PenIcon />}
              </button>
              <button
                className="secondary small"
                onClick={() => removePlayer(player.id)}
              >
                <DeleteIcon />
              </button>
            </div>
            {editingPlayer === player.id && (
              <PlayerEditForm
                player={player}
                updatePlayer={updatePlayer}
                closeEditForm={() => toggleEditForm(player.id)}
              />
            )}
          </li>
        ))}
      </PlayerList>
      <div className="children-wrapper">{children}</div>
    </PlayerListWrapper>
  );
};

export default PlayerListView;
