import { MaxWidthWrapper } from "../ui/MaxWidth";
import styled from "styled-components";
import { useOutletContext } from "react-router";

const ContestStartPageWrapper = styled.div`
  text-align: center;
`;

export const ContestStartPage = () => {
  const { tournamentName } = useOutletContext();
  return (
    <ContestStartPageWrapper>
      <MaxWidthWrapper>
        <h2>{tournamentName}</h2>
        <p>Börja med att lägga till spelare i menyn ovan.</p>
        <p>
          När du lagt till spelare kan du välja spelform i inställningar. Sedan
          kan du skapa spelschema.
        </p>
      </MaxWidthWrapper>
    </ContestStartPageWrapper>
  );
};
