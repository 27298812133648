import { GlobalStyles } from "./theme";
import Header from "./components/Header";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import { Contest } from "./components/Contest/Contest";
import Players from "./components/Contest/Players";
import Settings from "./components/Contest/Settings";
import Schedule from "./components/Contest/Schedule";
import Result from "./components/Contest/Result";
import KnockoutRoute from "./components/Contest/KnockoutRoute";
import { ContestStartPage } from "./components/Contest/ContestStartPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/" element={<Contest />}>
        <Route index element={<ContestStartPage />} />
        <Route path="spelare" element={<Players />} />
        <Route path="installning" element={<Settings />} />
        <Route path="pooler" element={<Schedule />} />
        <Route path="slutspel" element={<KnockoutRoute />} />
        <Route path="resultat" element={<Result />} />
      </Route>
    </Route>
  )
);

function Layout() {
  return (
    <>
      <GlobalStyles />
      <div className="main-app-wrapper">
        <Header />
        <Outlet />
      </div>
    </>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
