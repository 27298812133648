import React from "react";
import styled from "styled-components";
import { theme } from "../../../theme";

const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  .name {
    &.winner {
      font-weight: bold;
      color: var(--color-secondary);
    }
  }
  .result-control {
    button {
      border: 2px solid #ddd;
      background: #f1f1f1;
      border-radius: 5px;
      cursor: pointer;
      padding: 0 8px;
      height: 30px;
      ${theme.media.sm} {
        padding: 5px 7px;
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
      &.selected {
        border-color: #000;
      }
    }
  }
`;

export const Player = ({
  player,
  match,
  reportIndex,
  opponent,
  reportResults,
  updateResult,
  set,
}) => {
  const wonSets = match.result?.[player];
  const lostSets = match.result?.[opponent];
  const isPlayer1 = player === "player1";
  const reportResult = reportResults[match.matchId];
  return (
    <PlayerWrapper className="player">
      <div
        className={`name ${
          match.winner?.id === match[player].id ? "winner" : ""
        }`}
      >
        {match[player].name}{" "}
        {match[player].seed && (
          <span className="seed">({match[player].seed})</span>
        )}
      </div>
      {reportResult && (
        <div className="result-control">
          {Array.from({ length: set + 1 }, (_, index) => set - index).map(
            (sets) => (
              <button
                key={sets}
                onClick={() => {
                  updateResult(
                    reportIndex,
                    isPlayer1 ? sets : match.result?.["player1"],
                    !isPlayer1 ? sets : match.result?.["player2"]
                  );
                }}
                className={`${wonSets === sets ? "selected" : ""}`}
                disabled={sets === set && lostSets === set}
              >
                {sets}
              </button>
            )
          )}
        </div>
      )}
    </PlayerWrapper>
  );
};
