import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import TournamentForm from "./TournamentForm";
import SubMenu from "../SubMenu";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import { useEffect, useState } from "react";
import { updateKnockoutTree } from "./Common/generateKnockoutTree";
import styled from "styled-components";
import { sortObjectByKeys } from "./Common/helpers";
import GearIcon from "../ui/FontIcons/GearIcon";

export const minPlayers = 4;

export const ContestWrapper = styled.div`
  button.generate-schedule {
    margin: 0 auto 2rem;
  }
  p.schedule-warning {
    text-align: center;
    line-height: 1.5;
    color: var(--color-warning);
  }
  .seed {
    font-size: 0.8rem;
  }
`;

export const ContestPageTitle = styled.h1`
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
  text-align: center;
`;

export const ContestPageSubTitle = styled.h2`
  font-size: 1rem;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 0.5rem;
  margin-top: 2rem;
`;

export const ContestMessage = styled.p`
  text-align: center;
`;

export const initSchedule = {
  scheduleId: Date.now(),
  groups: [],
  rounds: [],
  type: "group-knockout",
  set: 3,
  players: [],
  groupOfPlayers: [],
};

export const Contest = () => {
  const [tournamentName, setTournamentName] = useState("");
  const [savedTournaments, setSavedTournaments] = useState({});
  const [editingPlayer, setEditingPlayer] = useState(null);
  const [schedule, setSchedule] = useState(initSchedule);
  const [reportResults, setReportResults] = useState({});

  useEffect(() => {
    // Reset the reportResults state when a new schedule is generated
    setReportResults({});
  }, [schedule.scheduleId]); // Monitor the unique schedule identifier

  const toggleReportResult = (matchId) => {
    setReportResults((prev) => ({
      ...prev,
      [matchId]: !prev[matchId],
    }));
  };

  //NEW TOURNAMENT
  const newTournament = ({ name }) => {
    if (name) {
      setTournamentName(name);
      setSchedule(initSchedule);

      const newTournaments = {
        ...savedTournaments,
        [name]: { schedule: initSchedule },
      };
      setSavedTournaments(sortObjectByKeys(newTournaments));
      localStorage.setItem("tournaments-list", JSON.stringify(newTournaments));
    } else {
      alert("Ange namn på turnering för att spara");
    }
  };

  //SAVE TOURNAMENT
  const saveTournament = (updatedSchedule = schedule) => {
    if (tournamentName) {
      const newTournaments = {
        ...savedTournaments,
        [tournamentName]: { schedule: updatedSchedule },
      };
      setSavedTournaments(sortObjectByKeys(newTournaments));
      localStorage.setItem("tournaments-list", JSON.stringify(newTournaments));
    }
  };

  const loadTournament = (name) => {
    setTournamentName(name);
    const tournament = savedTournaments[name];
    if (tournament) {
      //FIX FOR OLD FUNCTIONALITY (CAN BE REMOVED LATER)
      if (tournament.players?.length > 0)
        tournament.schedule.players = tournament.players;
      setSchedule(tournament.schedule);
    }
  };

  const removeTournament = (name) => {
    const newSavedTournaments = { ...savedTournaments };
    delete newSavedTournaments[name];
    setSavedTournaments(newSavedTournaments);
    localStorage.setItem(
      "tournaments-list",
      JSON.stringify(newSavedTournaments)
    );
    setTournamentName("");
  };

  //PLAYERS
  const updatePlayers = (updatedPlayers) => {
    updatedPlayers.sort((a, b) => b.ranking - a.ranking);
    const newSchedule = { ...schedule };
    newSchedule.groups = [];
    newSchedule.rounds = [];
    newSchedule.players = updatedPlayers;
    setSchedule(newSchedule);
    saveTournament(newSchedule);
  };

  const addPlayer = (player) => {
    const updatedPlayers = [...schedule.players, player];
    updatePlayers(updatedPlayers);
  };

  const removePlayer = (playerId) => {
    const updatedPlayers = schedule.players.filter(
      (player) => player.id !== playerId
    );
    updatePlayers(updatedPlayers);
  };

  const updatePlayer = (playerId, newName, newRanking) => {
    const updatedPlayers = schedule.players.map((player) => {
      if (player.id === playerId) {
        return { ...player, name: newName, ranking: newRanking };
      }
      return player;
    });
    updatePlayers(updatedPlayers);
  };

  const toggleEditForm = (playerId) => {
    setEditingPlayer(editingPlayer === playerId ? null : playerId);
  };

  const clearPlayers = () => {
    if (
      window.confirm("Detta kommer ta bort alla spelarna. Vill du fortsätta?")
    ) {
      const updatedSchedule = { ...schedule };
      updatedSchedule.groups =
        updatedSchedule.rounds =
        updatedSchedule.players =
          [];
      setSchedule(initSchedule);
    }
  };

  useEffect(() => {
    const storedTournaments = JSON.parse(
      localStorage.getItem("tournaments-list")
    );
    if (storedTournaments) {
      setSavedTournaments(sortObjectByKeys(storedTournaments));
    }
  }, []);

  const updateResult = (reportIndex, player1, player2) => {
    const updatedSchedule = { ...schedule };

    const targetMatch =
      reportIndex.length === 2
        ? updatedSchedule.rounds[reportIndex[0]][reportIndex[1]]
        : updatedSchedule.groups[reportIndex[0]][reportIndex[1]][
            reportIndex[2]
          ];

    const updatedMatch = {
      ...targetMatch,
      result: {
        player1,
        player2,
      },
    };

    updatedMatch.winner =
      player1 === schedule.set && !isNaN(parseInt(player2, 10))
        ? updatedMatch["player1"]
        : player2 === schedule.set && !isNaN(parseInt(player1, 10))
        ? updatedMatch["player2"]
        : false;

    if (reportIndex.length === 2) {
      updatedSchedule.rounds[reportIndex[0]][reportIndex[1]] = updatedMatch;
    } else {
      updatedSchedule.groups[reportIndex[0]][reportIndex[1]][reportIndex[2]] =
        updatedMatch;
    }

    if (
      updatedSchedule.type === "knockout" ||
      updatedSchedule.type === "group-knockout"
    )
      updatedSchedule.rounds = updateKnockoutTree(updatedSchedule.rounds);

    setSchedule(updatedSchedule);
    saveTournament(updatedSchedule);

    if (updatedMatch.winner) {
      const updatedResults = { ...reportResults };
      updatedResults[updatedMatch.matchId] = false;
      setReportResults(updatedResults);
    }
  };

  const hasPlayers = schedule.players.length >= minPlayers;
  const scheduleOngoing =
    (schedule.rounds?.length > 0 && schedule.type === "knockout") ||
    (schedule.groups?.length > 0 && schedule.type === "round-robin") ||
    (schedule.groups?.length > 0 && schedule.type === "group-knockout");

  const hasKnockout =
    schedule.type === "knockout" || schedule.type === "group-knockout";
  const hasGroups =
    schedule.type === "round-robin" || schedule.type === "group-knockout";

  return (
    <ContestWrapper>
      <TournamentForm
        tournamentName={tournamentName}
        setTournamentName={setTournamentName}
        saveTournament={saveTournament}
        savedTournaments={savedTournaments}
        loadTournament={loadTournament}
        removeTournament={removeTournament}
        newTournament={newTournament}
      />
      {tournamentName ? (
        <>
          <SubMenu className="sticky">
            <NavLink to="/spelare">Spelare</NavLink>
            {hasGroups && <NavLink to="/pooler">Pooler</NavLink>}
            {hasKnockout && <NavLink to="/slutspel">Slutspel</NavLink>}
            <NavLink to="/resultat">Resultat</NavLink>
            <NavLink to="/installning">
              <GearIcon />
            </NavLink>
          </SubMenu>
          <Outlet
            context={{
              schedule,
              minPlayers,
              addPlayer,
              editingPlayer,
              updatePlayer,
              removePlayer,
              hasPlayers,
              clearPlayers,
              toggleEditForm,
              setSchedule,
              updateResult,
              reportResults,
              toggleReportResult,
              scheduleOngoing,
              saveTournament,
              tournamentName,
            }}
          />
        </>
      ) : (
        <MaxWidthWrapper>
          <ContestMessage>
            <h2>
              Välkommen till appen för att göra din egna bordtennistävling.
            </h2>
            <p>Denna app är en beta-version.</p>
            <p>
              Starta med att klicka på plustecknet i menyn för att skapa din
              första klass. Du kan skapa flera klasser som sedan visas i
              rullisten ovan.
            </p>
            <p>
              I denna beta-version så sparas all data enbart på din lokala
              enheten.
            </p>
            <p>Allting sparas automatiskt hela tiden.</p>
            <p>
              Utvecklare:{" "}
              <a href="mailto:simon@ebelingwebb.se">Simon Ebeling</a>
            </p>
          </ContestMessage>
        </MaxWidthWrapper>
      )}
    </ContestWrapper>
  );
};
