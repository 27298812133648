import { ReactComponent as Logo } from "./ui/icons/table-tennis-paddle-ball-duotone.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledHeader = styled.header`
  .inner {
    max-width: 800px;
    margin: 0 auto;
  }
  background: var(--color-primary-dark);
  padding: 1rem 1rem;
  .logo {
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    svg {
      max-width: 25px;
      margin-right: 10px;
      .fa-primary {
        fill: var(--color-white);
      }
      .fa-secondary {
        fill: var(--color-primary);
      }
    }
    h1 {
      margin: 0;
      text-transform: uppercase;
      font-size: 1.2rem;
      a {
        color: var(--color-white);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const Header = () => {
  return (
    <>
      <StyledHeader id="main-header">
        <div className="inner">
          <div className="logo">
            <Logo />
            <h1>
              <Link to="/">Pingistävling.se</Link>
            </h1>
          </div>
        </div>
      </StyledHeader>
    </>
  );
};

export default Header;
