import React from "react";
import styled from "styled-components";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import RoundRobin from "../RoundRobin/RoundRobin";

const GroupKnockoutWrapper = styled.div`
  .knockout-title {
    margin-top: 2rem;
  }
`;

const GroupKnockout = () => {
  return (
    <MaxWidthWrapper>
      <GroupKnockoutWrapper>
        <RoundRobin smallGroup={true} />
      </GroupKnockoutWrapper>
    </MaxWidthWrapper>
  );
};

export default GroupKnockout;
