import { useOutletContext } from "react-router";
import ButtonMenu from "../ButtonMenu";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import { ContestPageSubTitle, ContestPageTitle } from "./Contest";

const Settings = () => {
  const { schedule, setSchedule, scheduleOngoing, saveTournament } =
    useOutletContext();

  const typeHandler = (scheduleType) => {
    const newSchedule = { ...schedule };
    newSchedule.type = scheduleType;
    newSchedule.groups = newSchedule.rounds = [];
    setSchedule(newSchedule);
    saveTournament(newSchedule);
  };

  const setHandler = (scheduleType) => {
    const newSchedule = { ...schedule };
    newSchedule.set = scheduleType;
    newSchedule.groups = newSchedule.rounds = [];
    setSchedule(newSchedule);
    saveTournament(newSchedule);
  };

  return (
    <MaxWidthWrapper>
      <ContestPageTitle>Inställningar</ContestPageTitle>
      {scheduleOngoing && (
        <p className="schedule-warning">
          Obs! Denna klass är nu igång. Om du gör förändringar i inställningarna
          nu kommer spelschemat att behöva skapas på nytt.
        </p>
      )}
      <ContestPageSubTitle>Spelform</ContestPageSubTitle>
      <ButtonMenu>
        {[
          { name: "Poolspel", type: "group-knockout" },
          { name: "Slutspel", type: "knockout" },
          { name: "Storpool", type: "round-robin" },
        ].map((item, index) => (
          <button
            key={index}
            className={schedule.type === item.type ? "active" : ""}
            onClick={() => typeHandler(item.type)}
          >
            {item.name}
          </button>
        ))}
      </ButtonMenu>
      <ContestPageSubTitle>Antal set (bäst av)</ContestPageSubTitle>
      <ButtonMenu>
        {[
          { name: "7 set", value: 4 },
          { name: "5 set", value: 3 },
          { name: "3 set", value: 2 },
          { name: "1 set", value: 1 },
        ].map((item, index) => (
          <button
            key={index}
            className={schedule.set === item.value ? "active" : ""}
            onClick={() => setHandler(item.value)}
          >
            {item.name}
          </button>
        ))}
      </ButtonMenu>
    </MaxWidthWrapper>
  );
};

export default Settings;
