import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import styled from "styled-components";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import { ToggleTitle } from "../../ui/ToggleTitle";
import { calculatePlayerStats } from "../Common/calcGroupResult";
import { ContestPageTitle } from "../Contest";

const ResultTableWrapper = styled.div`
  overflow: auto;
  > h2 {
    text-align: center;
  }
`;

const ResultToplist = styled.table`
  width: 100%;
  min-width: 450px;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }

  td {
    background-color: var(--color-light);
  }
  &.no-toplist {
    th {
      background-color: var(--color-secondary-light);
    }
    tbody {
      tr:nth-of-type(2) td {
        border-bottom-color: #777;
        border-bottom-width: 2px;
      }
    }
  }
  &:not(.no-toplist) {
    tbody {
      tr:nth-of-type(1) {
        background: #c9b037;
      }
      tr:nth-of-type(2) {
        background: #c5c5c5;
      }
      tr:nth-of-type(3) {
        background: #dbb379;
      }
    }
  }
`;

export const ResultsTable = ({ groupIndex, className }) => {
  const [playerStats, setPlayerStats] = useState([]);
  const { schedule } = useOutletContext();
  useEffect(() => {
    const stats = calculatePlayerStats(
      schedule.groupOfPlayers[groupIndex],
      schedule.groups[groupIndex]
    );
    setPlayerStats(stats);
  }, [schedule, groupIndex]);

  if (typeof schedule.groupOfPlayers[groupIndex] === undefined) return null;

  return (
    <ResultTableWrapper>
      <ResultToplist className={className}>
        <thead>
          <tr>
            <th>Spelare</th>
            <th>Matcher</th>
            <th>Kvot</th>
            <th>Set</th>
          </tr>
        </thead>
        <tbody>
          {playerStats.map(
            ({ player, wins, losses, matchesPlayed, setsWon, setsLost }) => (
              <tr key={player.id}>
                <td>
                  {player.name}{" "}
                  {player.seed && <span className="seed">({player.seed})</span>}
                </td>
                <td>{matchesPlayed}</td>
                <td>
                  {wins}-{losses}
                </td>
                <td>
                  {setsWon}-{setsLost}
                </td>
              </tr>
            )
          )}
        </tbody>
      </ResultToplist>
    </ResultTableWrapper>
  );
};

const AllResult = ({ smallGroup = false }) => {
  const { schedule } = useOutletContext();
  return (
    <MaxWidthWrapper>
      <ContestPageTitle>Resultat storpool</ContestPageTitle>
      {schedule.groups.map((group, index) => (
        <ToggleTitle key={index} title={`Division ${index + 1}`}>
          <ResultsTable groupIndex={index} />
        </ToggleTitle>
      ))}
    </MaxWidthWrapper>
  );
};

export default AllResult;
