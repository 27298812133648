import React, { useState } from "react";
import styled from "styled-components";
import DownIcon from "./FontIcons/DownIcon";

const ToggleTitleButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0.75rem 0;
  width: 100%;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #c1c1c1;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  h2 {
    margin: 0;
  }
  svg {
    transition: all 0.2s ease-in;
    font-size: 1.1rem;
  }
  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const ToggleTitleContent = styled.div`
  padding-bottom: 1rem;
`;

export const ToggleTitle = ({ title, htmlId, children }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <ToggleTitleButton
        id={htmlId}
        className={`${show ? "open" : ""}`}
        onClick={() => setShow(!show)}
      >
        <h2>{title}</h2>
        <DownIcon />
      </ToggleTitleButton>
      {show && <ToggleTitleContent>{children}</ToggleTitleContent>}
    </>
  );
};
