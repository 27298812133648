import React from "react";
import styled from "styled-components";
import { Button } from "../../ui/Button";
import { useOutletContext } from "react-router";
import { generateKnockoutTree } from "../Common/generateKnockoutTree";
import KnockoutTree from "./KnockoutTree";
import { NoWidthWrapper } from "../../ui/MaxWidth";

const KnockoutWrapper = styled.div`
  padding-bottom: 3rem;
  text-align: left;
`;

export const Knockout = () => {
  const {
    schedule,
    setSchedule,
    minPlayers,
    hasPlayers,
    updateResult,
    reportResults,
    toggleReportResult,
  } = useOutletContext();

  // Function to generate the schedule
  const generateScheduleHandler = () => {
    if (schedule.players.length >= minPlayers) {
      const newSchedule = { ...schedule };
      newSchedule.rounds = generateKnockoutTree(schedule.players);
      newSchedule.type = "knockout";
      setSchedule(newSchedule);
    } else {
      alert(`Lägg till minst ${minPlayers} spelare`);
    }
  };

  const hasRounds = schedule.rounds?.length > 0;

  return (
    <NoWidthWrapper>
      <KnockoutWrapper>
        {hasPlayers && (
          <Button
            className="generate-schedule"
            onClick={generateScheduleHandler}
          >
            Skapa slutspelsträd
          </Button>
        )}
        {hasRounds && (
          <KnockoutTree
            {...{
              schedule,
              updateResult,
              reportResults,
              toggleReportResult,
            }}
          />
        )}
      </KnockoutWrapper>
    </NoWidthWrapper>
  );
};

export default Knockout;
