import { shuffleArray } from "./generateKnockoutTree";

export const groupPlayersWithSeedings = (players, maxPlayersPerGroup = 4) => {
  const sortedPlayers = [...players].sort((a, b) => b.ranking - a.ranking);
  const numPlayers = players.length;
  const numGroups = Math.ceil(players.length / maxPlayersPerGroup);
  const numIncompletedGroups =
    numPlayers % maxPlayersPerGroup === 0
      ? 0
      : maxPlayersPerGroup - (numPlayers % maxPlayersPerGroup);

  const groups = Array.from({ length: numGroups }, () => []);

  //SEEDED PLAYERS
  const seedPlayers = sortedPlayers.splice(0, numGroups);
  for (let i = 0; i < seedPlayers.length; i++) {
    seedPlayers[i].seed = i + 1;
  }

  //NON SEEDED PLAYERS
  const restPlayers = shuffleArray(sortedPlayers);
  for (let i = 0; i < restPlayers.length; i++) {
    restPlayers[i].seed = false;
  }

  //PLACE PLAYERS
  for (let i = 0; i < groups.length; i++) {
    //SEEDED PLAYER
    groups[i].push(...seedPlayers.splice(0, 1));

    //REST PLAYERS
    const numRestPlayers = maxPlayersPerGroup - 1;
    groups[i].push(
      ...restPlayers.splice(
        0,
        i < numIncompletedGroups ? numRestPlayers - 1 : numRestPlayers
      )
    );
  }

  return groups;
};

export const groupPlayersByRanking = (players, maxPlayersPerGroup = 8) => {
  const sortedPlayers = [...players].sort((a, b) => b.ranking - a.ranking);

  //RESET SEEDINGS
  for (let i = 0; i < sortedPlayers.length; i++) {
    sortedPlayers[i].seed = false;
  }

  const numberOfGroups = Math.ceil(sortedPlayers.length / maxPlayersPerGroup);
  const numberInGroups = Math.ceil(sortedPlayers.length / numberOfGroups);

  const groups = [];

  for (let i = 0; i < numberOfGroups; i++) {
    groups.push(
      sortedPlayers.slice(i * numberInGroups, (i + 1) * numberInGroups)
    );
  }

  return groups;
};

const generateGroupSchedule = (players) => {
  const rounds = [];
  let matchId = 1;
  let hasByePlayer = false;

  if (players.length % 2 !== 0) {
    players.push({ id: "bye", name: "Bye", ranking: 0 });
    hasByePlayer = true;
  }

  const numPlayers = players.length;

  players.sort((a, b) => b.ranking - a.ranking);

  for (let round = 0; round < numPlayers - 1; round++) {
    const matches = [];
    for (let match = 0; match < numPlayers / 2; match++) {
      const player1 = players[match];
      const player2 = players[numPlayers - 1 - match];

      if (player1.id !== "bye" && player2.id !== "bye") {
        matches.push({
          matchId: matchId++,
          player1,
          player2,
          result: { player1: false, player2: false },
        });
      }
    }
    rounds.push(matches);

    // Rotate players, keeping the first one fixed
    players.splice(1, 0, players.pop());
  }

  // Remove the bye player if it was added
  if (hasByePlayer) {
    players.pop();
  }

  return rounds;
};

export default generateGroupSchedule;
