import { useOutletContext } from "react-router";
import { Link } from "react-router-dom";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import GroupKnockoutTree from "./GroupKnockout/GroupKnockoutTree";
import Knockout from "./Knockout/Knockout";

const KnockoutRoute = () => {
  const { schedule } = useOutletContext();
  if (schedule.type === "knockout") return <Knockout />;
  if (schedule.type === "group-knockout") return <GroupKnockoutTree />;
  return (
    <MaxWidthWrapper>
      <p>
        Denna spelform har inget slutspel. För att ändra gå till{" "}
        <Link to="/tavling/installning">inställningar</Link>
      </p>
    </MaxWidthWrapper>
  );
};

export default KnockoutRoute;
