import React from "react";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import KnockoutResult from "../Knockout/KnockoutResult";

const GroupKnockoutResult = () => {
  return (
    <MaxWidthWrapper>
      <KnockoutResult />
    </MaxWidthWrapper>
  );
};

export default GroupKnockoutResult;
