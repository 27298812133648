import React from "react";
import { useOutletContext } from "react-router";
import styled from "styled-components";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import { ContestMessage, ContestPageTitle } from "../Contest";

const KnockoutResultWrapper = styled.div`
  overflow: auto;
  > h2 {
    text-align: center;
  }
  .podium {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 300px;
    margin-top: 50px;
    position: relative;
  }
  .podium .place {
    width: 100px;
    text-align: center;
    margin: 0 10px;
    padding: 20px 0;
    color: white;
    font-weight: bold;
    position: relative;
  }
  .podium .place .name {
    position: absolute;
    bottom: 100%;
    width: 100%;
    left: 0;
    right: 0;
    color: black;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    span {
      margin: 5px 0;
    }
  }
  .first {
    background-color: gold;
    height: 200px;
  }
  .second {
    background-color: silver;
    height: 150px;
  }
  .third {
    background-color: #cd7f32; /* Bronze color */
    height: 100px;
  }
`;

const KnockoutResult = () => {
  const { schedule } = useOutletContext();

  const toplist = [];

  if (schedule.rounds.length === 0) return null;

  const lastRound = schedule.rounds[schedule.rounds.length - 1];

  if (!lastRound[0].winner)
    return <ContestMessage>Slutspelet är ej färdigspelat</ContestMessage>;

  const backRound = schedule.rounds.length === 2 ? 2 : 3;

  for (
    let i = schedule.rounds.length - 1;
    i >= schedule.rounds.length - backRound;
    i--
  ) {
    const round = schedule.rounds[i];
    for (let j = 0; j < round.length; j++) {
      if (round[j].winner && !toplist.includes(round[j].winner))
        toplist.push(round[j].winner);
    }
  }

  if (toplist.length < 2) return null;

  return (
    <MaxWidthWrapper>
      <KnockoutResultWrapper>
        <ContestPageTitle id="result">Resultat</ContestPageTitle>
        <div className="podium">
          <div className="place second">
            <div className="name">
              <span>{toplist[1].name}</span>
            </div>
            2a
          </div>
          <div className="place first">
            <div className="name">
              <span>{toplist[0].name}</span>
            </div>
            1a
          </div>
          {toplist.length > 3 && (
            <div className="place third">
              <div className="name">
                <span>{toplist[2].name}</span>
                <span>{toplist[3].name}</span>
              </div>
              3a
            </div>
          )}
        </div>
      </KnockoutResultWrapper>
    </MaxWidthWrapper>
  );
};

export default KnockoutResult;
