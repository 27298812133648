export const calculatePlayerStats = (players, rounds) => {
  // Initialize player statistics
  const stats = players.map((player) => ({
    player,
    wins: 0,
    losses: 0,
    matchesPlayed: 0,
    setsWon: 0,
    setsLost: 0,
  }));

  // Calculate player statistics from the schedule
  rounds.forEach((round) => {
    round.forEach((match) => {
      const result = match.result;
      if (result) {
        const player1Sets = result.player1;
        const player2Sets = result.player2;

        if (match.winner) {
          const winner = match.winner;
          const loser =
            match.winner.id === match.player1.id
              ? match.player2
              : match.player1;

          // Update player statistics
          const winnerStats = stats.find(
            (stat) => stat.player.id === winner.id
          );
          const loserStats = stats.find((stat) => stat.player.id === loser.id);

          if (loser.id !== 0) {
            winnerStats.wins++;
            loserStats.losses++;

            winnerStats.matchesPlayed++;
            loserStats.matchesPlayed++;

            const winningSets =
              player1Sets > player2Sets ? player1Sets : player2Sets;
            const losingSets =
              player1Sets < player2Sets ? player1Sets : player2Sets;

            winnerStats.setsWon += winningSets;
            winnerStats.setsLost += losingSets;

            loserStats.setsWon += losingSets;
            loserStats.setsLost += winningSets;
          }
        }
      }
    });
  });

  // Calculate set difference for each player
  stats.forEach((player) => {
    player.setDifference = player.setsWon - player.setsLost;
  });

  // Sort player statistics by wins and set difference
  stats.sort((a, b) => {
    if (a.wins !== b.wins) {
      return b.wins - a.wins; // Sort by wins
    } else {
      return b.setDifference - a.setDifference; // If wins are equal, sort by set difference
    }
  });

  return stats;
};
