import React, { useState } from "react";
import { Button } from "../ui/Button";
import styled from "styled-components";
import { theme } from "../../theme";
import PlusIcon from "../ui/FontIcons/PlusIcon";
import DeleteIcon from "../ui/FontIcons/DeleteIcon";
import TrashIcon from "../ui/FontIcons/TrashIcon";

const Wrapper = styled.div`
  .save-tournament,
  .load-tournament {
    display: flex;
    width: 100%;
    select {
      flex-grow: 1;
      margin-right: 10px;
    }
  }
  .load-tournament {
    margin-bottom: 1rem;
  }
  .tournament-sticky {
    // position: fixed;
    // left: 0;
    // bottom: 0;
    width: 100%;
    padding: 1rem;
    box-shadow: 1px 1px 1px #000;
    background: var(--color-primary-medium2-light);
    padding: 7px;
    box-shadow: -1px 0px 4px #00000087;

    .inner {
      max-width: 800px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      .add-new {
        margin-right: 10px;
        ${theme.media.sm} {
          margin-right: 5px;
        }
      }
      .save {
        margin-left: 10px;
        ${theme.media.sm} {
          margin-left: 5px;
        }
      }
      .save,
      .remove {
        ${theme.media.sm} {
          font-size: 0.8rem;
        }
      }
      select {
        flex-grow: 1;
        margin-right: 10px;
        border-radius: 3px;
        ${theme.media.sm} {
          margin-right: 5px;
        }
      }
    }
  }
  .tournament-form {
  }
  .hide-sm {
    ${theme.media.sm} {
      display: none;
    }
  }
`;
const AddTournamentModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: white;
  padding: 2rem;
  ${theme.media.sm} {
    padding: 1.5rem;
  }
  box-shadow: 0px 0px 6px #0000007a;
  .inner form {
    display: flex;
  }
  .close {
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const TournamentModal = ({ setShowAddModal, newTournament }) => {
  const [inputValue, setInputValue] = useState("");
  return (
    <AddTournamentModal>
      <div className="popup">
        <div className="inner">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              newTournament({ name: inputValue });
              setShowAddModal(false);
            }}
          >
            <Button
              type="button"
              className="close text"
              onClick={() => setShowAddModal(false)}
            >
              <DeleteIcon />
            </Button>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Namn"
            />
            <Button type="submit" className="save" disabled={!inputValue}>
              Spara<span className="hide-sm"> klass</span>
            </Button>
          </form>
        </div>
      </div>
    </AddTournamentModal>
  );
};

const TournamentForm = ({
  tournamentName,
  setTournamentName,
  savedTournaments,
  loadTournament,
  removeTournament,
  newTournament,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleRemoveTournament = () => {
    if (
      tournamentName &&
      window.confirm(`Är du säker att du vill ta bort: ${tournamentName}?`)
    ) {
      removeTournament(tournamentName);
      loadTournament("");
    }
  };

  const tournaments = Object.keys(savedTournaments);

  return (
    <Wrapper>
      {showAddModal && (
        <TournamentModal
          {...{
            setShowAddModal,
            newTournament,
          }}
        />
      )}
      <form className="tournament-form">
        <div className="tournament-sticky">
          <div className="inner">
            <Button
              type="button"
              className="add-new"
              onClick={() => setShowAddModal(true)}
            >
              <PlusIcon />
            </Button>
            <select
              onChange={(e) => {
                loadTournament(e.target.value);
              }}
              value={tournamentName}
            >
              <option value="">
                {tournaments.length > 0 ? (
                  <>Alla dina klasser..</>
                ) : (
                  <>Skapa din första klass..</>
                )}
              </option>
              {Object.keys(savedTournaments).map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
            <Button
              type="button"
              className="secondary remove"
              onClick={handleRemoveTournament}
              disabled={!tournamentName}
            >
              <TrashIcon />
            </Button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default TournamentForm;
