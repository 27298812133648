import styled from "styled-components";

const NavWrapper = styled.nav`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  > button {
    margin-right: 1rem;
    border: none;
    background: #ddd;
    color: #000;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    &.active {
      background: var(--color-primary);
      color: white;
    }
  }
`;

const ButtonMenu = ({ children, className = "" }) => {
  return <NavWrapper className={`sub-nav ${className}`}>{children}</NavWrapper>;
};

export default ButtonMenu;
