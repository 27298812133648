import React from "react";
import styled from "styled-components";
import { Match } from "../Match/Match";

const ScheduleWrapper = styled.div`
  text-align: left;
  .round {
    margin: 20px 0;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    > h3 {
      background: var(--color-primary-light);
      padding: 1rem;
      margin: 0;
    }
  }
`;

const GroupSchedule = ({
  group,
  groupIndex,
  updateResult,
  reportResults,
  toggleReportResult,
  set,
}) => {
  if (group.length === 0) return null;

  return (
    <ScheduleWrapper>
      {group?.map((round, roundIndex) => (
        <div key={roundIndex} className="round">
          <h3>Omgång {roundIndex + 1}</h3>
          {round.map((match, matchIndex) => {
            return (
              <Match
                key={matchIndex}
                match={match}
                reportIndex={[groupIndex, roundIndex, matchIndex]}
                toggleReportResult={toggleReportResult}
                reportResults={reportResults}
                updateResult={updateResult}
                set={set}
              />
            );
          })}
        </div>
      ))}
    </ScheduleWrapper>
  );
};

export default GroupSchedule;
