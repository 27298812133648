import { createGlobalStyle } from "styled-components";

export const theme = {
  media: {
    sm: "@media screen and (max-width: 500px)",
    md: "@media screen and (min-width: 500px)",
    lg: "@media screen and (min-width: 800px)",
  },
};

export const GlobalStyles = createGlobalStyle`

    html{
        font-size: 16px;
        box-sizing: border-box;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        font-family: var(--font-family-body);
        font-weight: var(--font-weight-body);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--color-font);
    }

    h1, h2, h3, h4, h5, h6{
        font-weight: var(--font-weight-bold); 
        line-height: 1.3em;
    }

    p{
        line-height: 1.5;
    }

    code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    button, textarea, input, select{
        font-family: var(--font-family-body);
        font-weight: var(--font-weight-body);
    }

    textarea, input, select{
        font-size: 1rem;
    }

    a, a:visited{
        color: var(--color-primary);
    }
`;
